<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/driver/home">{{ $t("Area") }}</router-link>
      </label>
      <span>/</span>
      <label>
        <router-link :to="`/driver/events/${branch_id}/${period_id}`">{{ $t("Events") }}</router-link>
      </label>
      <span>/</span>
      <label active>تفاصيل المشترك</label>
    </div>

    <h2 class="mb-0 mt-7 mx-7">تفاصيل المشترك</h2>

    <div v-loading="loading">
    <v-card-text>
      <v-container fluid>
        <el-card v-if="details" style="border: 1px solid green">
          <v-container fluid>
            <v-row>
              <v-col  :cols="$vuetify.breakpoint.mdAndUp?'6': '12'">
                <h3>اسم العميل: {{ details.name }}</h3>
              </v-col>
              <v-col  :cols="$vuetify.breakpoint.mdAndUp?'2': '12'">
                <h3>المدينة: {{details.city}}</h3>
              </v-col>
              <v-col  :cols="$vuetify.breakpoint.mdAndUp?'2': '12'">
                <h3>الحي: {{ details.branch }}</h3>
              </v-col>
              <v-col  :cols="$vuetify.breakpoint.mdAndUp?'2': '12'">
                <h3>الجهة: {{details.company}}</h3>
              </v-col>
              <v-col  :cols="$vuetify.breakpoint.mdAndUp?'2': '12'">
                <h3>رقم الجوال: {{ details.mobile }}</h3>
              </v-col>

              <v-col  :cols="$vuetify.breakpoint.mdAndUp?'2': '12'">
                <h3>طريقة التسليم:  {{details.delivery_notes}}</h3>
              </v-col>

              <v-col  :cols="$vuetify.breakpoint.mdAndUp?'2': '12'">
                <h3>حالة التوصيل:
                   <span v-if="details.driver_delivery ==2">لم يتم التسليم</span> 
                   <span v-else-if="details.driver_delivery ==1">تم التسليم</span>
                  <!-- <span class="mx-2">3.73 Am</span> -->
                  </h3>
              </v-col>

              <v-col  :cols="$vuetify.breakpoint.mdAndUp?'2': '12'">
                <el-button v-if="details.driver_delivery ==0" @click="delivarModal = true;" class="mx-2" type="success">تسليم</el-button>
              </v-col>
                          <v-col  :cols="$vuetify.breakpoint.mdAndUp?'2': '12'">

                <el-button v-if="details.driver_delivery ==0" @click="notDelivarModal= true;" class="mx-2" type="danger">إلغاء التسليم</el-button>
              </v-col>
            </v-row>
          </v-container>
        </el-card>
      </v-container>
    </v-card-text>

    
    </div>

    <v-dialog v-model="delivarModal" width="30rem">
      <v-card>
        <v-toolbar style="background:green !important;" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("Confirm") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 class="clr-primary">
           هل أنت متأكد من عملية التسليم
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="delivarModal = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="delivar(1)"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="notDelivarModal" width="30rem">
      <v-card>
        <v-toolbar style="background:red !important;" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("Confirm") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 class="clr-primary">
           هل أنت متأكد من عملية  إلغاء التسليم
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="notDelivarModal = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="delivar(0)"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  name: "driverEventsDetails",
  data() {
    return {
      delivarModal:false,
      notDelivarModal:false,
      details: null,
      loading: false,
      branch_id: localStorage.getItem('event_branch_id') || null,
      period_id: localStorage.getItem('event_period_id') || null,
    };
  },
  methods: {
    delivar(val) {
      let sendData = {
        subscription_detail_id: this.$route.params.id2,
        driver_delivery: val
      }
      this.$store.dispatch(`driverDetails/update_driver_delivery`, sendData).then(res =>{
        this.notifySuccess(`UpdateStatus`, res);
        if(this.delivarModal) this.delivarModal=false;
        if(this.notDelivarModal) this.notDelivarModal=false;
        this.loadData();
      })
    },
    loadData() {
      this.loading= true;
      this.details = null;
    let send = {
      subscription_id: this.$route.params.id,
      subscription_detail_id: this.$route.params.id2,
    }
   this.$store
        .dispatch(`driverDetails/get_profile`, send)
        .then((res) => {
          this.details= res.data;
          this.loading= false;
        });
    },
  },
  mounted() {
    this.loadData();
  }
};
</script>
